/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unused-vars */
import { ILangState } from '@contextTypes';
import { IPage, ILocation, IPost, IHomePage } from '@standardTypes';

import React, { useMemo } from 'react';

import { LangCtx, useContextState } from '@components/contexted';
import { Layout } from '@components/layout';
import {
  HeroSection,
  BannersList,
  PostGrid,
  PeopleSection
} from '@components/organisms/standard';

import TestingSVG from './TestingSVG';

interface HomeProps {
  pageContext: {
    page: IHomePage;
    translationUrls: Record<string, string>;
    pages: Array<IPage>;
    posts: Array<IPost>;
  };
  location: ILocation;
}

// Notatka odnośnie pracy z multijęzycznością (proszę nie usuwać)
// https://five-barge-bd2.notion.site/Multij-zyczno-3e7e078299f7416e9f8bec7486d2b473
// Jakby pojawił się jakiś nowy problem proszę pisać do mnie (Adam)

const IndexPage: React.FC<HomeProps> = ({ pageContext }) => {
  const { page, translationUrls, posts } = pageContext;

  const {
    ACF_HomePageHeroSlider,
    ACF_HomePageBannersList,
    ACF_HomePagePeople
  } = page;

  return (
    <Layout {...page} translationUrls={translationUrls}>
      <HeroSection acfData={ACF_HomePageHeroSlider} />
      <BannersList acfData={ACF_HomePageBannersList} />
      <PeopleSection acfData={ACF_HomePagePeople} />
      <PostGrid acfData={posts} />
    </Layout>
  );
};

export default IndexPage;
